export const WebsiteData={
    campaign_title:"Fun Spin",
    main_page_sub_title:'Dive into a world of free social casino games! Challenge your friends, feel the thrill, and win big without spending a dime!',
    blockData : [
  {
    "img": "./img/blocks/bl22.png",
    "text": "Be part of an interactive community where players connect, chat, and share their adventures. Join now and take your gaming to new heights!"
  },
  {
    "img": "./img/blocks/bl1.png",
    "text": "Be part of an interactive community where players connect, chat, and share their adventures. Join now and take your gaming to new heights!"
  },
  {
    "img": "./img/blocks/bl7.png",
    "text": "We offer a unique platform for gamers to interact, share experiences, and enjoy games together. Join our friendly community today!"
  },
  {
    "img": "./img/blocks/bl14.png",
    "text": "Discover a dynamic community where gamers interact, share stories, and chat. Join us now to make your gaming experience even better!"
  },
  {
    "img": "./img/blocks/bl17.png",
    "text": "We offer a social platform full of fun interactions where gamers connect and share their experiences. Become part of our community today!"
  },
  {
    "img": "./img/blocks/bl3.png",
    "text": "We’ve built a lively platform where players chat, interact, and share their epic gaming moments. Join our friendly community to enhance your adventures!"
  }
],
    bg : "./img/bg/bg_41.png",
    main_title : "Immerse yourself in an exciting new world of social games that connect and entertain in",
    phone : "(545) 378-7043",
    logo:"./img/logo/lg7.png",
    colors : [
  "#11962E",
  "#607EC3"
],
    why_with_us_texts : [
  {
    "why_text": "Every spin, every wager, and every moment of gameplay is a chance to win big and be part of an exciting social gaming experience.",
    "why_label": "Chance to Win Big"
  },
  {
    "why_text": "We offer a variety of free games that will keep you engaged while rewarding your every spin and bet with exciting prizes.",
    "why_label": "Variety and Engagement"
  },
  {
    "why_text": "Every spin, every wager, and every moment of gameplay is a chance to win big and be part of an exciting social gaming experience.",
    "why_label": "Chance to Win Big"
  }
]}