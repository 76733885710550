import * as i from "./imports";

export const Header = () => {
  const location = i.useLocation();
  const block = location.pathname === "/";
  const [anchorEl, setAnchorEl] = i.React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = i.useNavigate();
  const ref = i.useRef(null);
  const isInView = i.useInView(ref, { once: false });
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className=" w-full h-[72px] py-[16px]" style={{ zIndex: 9999 }}>
      <div className="w-full h-[50px] flex justify-between items-center px-[16px] ">
        <i.motion.div
          animate={{ rotate: isInView ? [360, 0, 0, 360] : 0 }}
          ref={ref}
        >
          <img
            src={i.WebsiteData.logo}
            width={100}
            height={30}
            alt="logo"
            className="cursor-pointer pt-[10px]"
            onClick={() => {
              navigate(i.ROUTER.HOME);
            }}
          />
        </i.motion.div>
        <div className="lg:hidden">
          <i.Button
            id="fade-button"
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <i.MenuIcon />
          </i.Button>
          <i.Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={i.Fade}
          >
            {i.routerLink(i.ROUTER.HOME, "Home", handleClose)}
            {i.routerLink(i.ROUTER.HOTTEST_GAMES, "Hottest games", handleClose)}
            {i.routerLink(i.ROUTER.NEWEST_GAMES, "Newest games", handleClose)}
            {i.routerLink(i.ROUTER.ALL_GAMES, "All games", handleClose)}
            {i.routerLink(i.ROUTER.ABOUT_US, "About us", handleClose)}
            {i.routerLink(i.ROUTER.CONTACT, "Contact us", handleClose)}
          </i.Menu>
        </div>
        <div
          className={`hidden lg:flex  gap-[15px] justify-center ${
            block ? "w-full max-w-full" : "max-w-[637px]"
          }`}
        >
          {i.routerLinkXl(i.ROUTER.HOME, "Home", i.WebsiteData.colors[0], true)}
          {i.routerLinkXl(
            i.ROUTER.HOTTEST_GAMES,
            "Hottest games",
            i.WebsiteData.colors[0],
            true
          )}
          {i.routerLinkXl(
            i.ROUTER.NEWEST_GAMES,
            "Newest games",
            i.WebsiteData.colors[0],
            true
          )}
          {i.routerLinkXl(
            i.ROUTER.ALL_GAMES,
            "All games",
            i.WebsiteData.colors[0],
            true
          )}
          {i.routerLinkXl(
            i.ROUTER.ABOUT_US,
            "About us",
            i.WebsiteData.colors[0],
            true
          )}
          {i.routerLinkXl(
            i.ROUTER.CONTACT,
            "Contact us",
            i.WebsiteData.colors[0],
            true
          )}
        </div>
      </div>
    </div>
  );
};
