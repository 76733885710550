import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import { ROUTER } from "./routers";
import { BaseLayout } from "./widget/layouts/baselayout";
import { routeComponent } from "./shared/components";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
const Contact = React.lazy(() => import("./pages/contact/index"));
const About = React.lazy(() => import("./pages/about/index"));
const AllGames = React.lazy(() => import("./pages/all-games/index"));
const Cookies = React.lazy(() => import("./pages/cookies/index"));
const Terms = React.lazy(() => import("./pages/terms/index"));
const Main = React.lazy(() => import("./pages/main/index"));
export {
  React,
  useSelector,
  CircularProgress,
  useEffect,
  useState,
  Router,
  Routes,
  ROUTER,
  BaseLayout,
  routeComponent,
  Contact,
  About,
  AllGames,
  Cookies,
  Terms,
  Main,
};
