import * as i from "./imports";
export const routerLink = (
  link: string,
  title: string,

  handleClose: () => void
) => {
  return (
    <i.Link to={link}>
      <i.MenuItem onClick={handleClose}>{title}</i.MenuItem>
    </i.Link>
  );
};

export const routerLinkXl = (
  link: string,
  title: string,
  color: string,
  header?: boolean
) => {
  return (
    <i.Link to={link}>
      <span
        className={`font-bold  transition duration-300 ease-in-out`}
        style={{
          color: !Array.isArray(color) ? i.getOppositeColor(color) : color,
          fontSize: "16px",
          filter: "drop-shadow(0 0 0 rgba(0, 0, 0, 0))",
          transition: "filter 0.3s ease-in-out",
        }}
        onMouseEnter={(e) => {
          (e.currentTarget as HTMLElement).style.filter =
            "drop-shadow(0 5px 10px rgba(128, 0, 128, 1))";
        }}
        onMouseLeave={(e) => {
          (e.currentTarget as HTMLElement).style.filter =
            "drop-shadow(0 0 0 rgba(0, 0, 0, 0))";
        }}
      >
        {title}
      </span>
    </i.Link>
  );
};
