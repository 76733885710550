import * as i from "./imports";

export const BlockTextImg = (props: i.BlockTextImgType) => {
  const { img, textColor, text, idx } = props;
  const reverse = idx % 2 === 0;
  const ref = i.useRef(null);
  const isInView = i.useInView(ref, { once: false });

  return (
    <i.motion.div
      ref={ref}
      initial="hidden"
      whileInView="visible"
      transition={{ delay: 1 }}
      style={{
        transform: isInView
          ? "none"
          : reverse
          ? "translateX(-200px)"
          : "translateX(200px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
      }}
      className={`w-full h-fit flex flex-col lg:flex-row ${
        reverse ? "lg:flex-row-reverse" : null
      } items-center justify-between`}
    >
      <img
        src={img}
        className="w-full max-h-[500px] object-contain max-w-[500px]"
        alt="Bottom Image"
        style={{
          filter: `drop-shadow(0px 0px 20px ${
            i.getOppositeColor(textColor) === "white" ? "white" : "red"
          })`,
        }}
      />
      <p
        style={{
          color: i.getOppositeColor(textColor),
        }}
        className="text-break px-[5%] text-[20px] italic"
      >
        {text}
      </p>
    </i.motion.div>
  );
};
