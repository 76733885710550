import * as i from "./imports";

export const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  const ref = i.useRef(null);
  const isInView = i.useInView(ref, { once: false });

  return (
    <div className="w-full h-fit min-h-[343px] mt-auto flex flex-col justify-start items-center">
      <div className="flex flex-col justify-start items-center gap-[15px]">
        <div className="w-full h-fit flex mx-[32px] flex-col items-center md:flex-row justify-between gap-[16px] m-[2rem]">
          <i.Link to={i.ROUTER.HOME}>
            <i.motion.div
              animate={{ rotate: isInView ? [360, 0, 0, 360] : 0 }}
              ref={ref}
            >
              {" "}
              <img
                src={i.WebsiteData.logo}
                height={63}
                width={200}
                alt="logo"
                className="cursor-pointer"
              />
            </i.motion.div>
          </i.Link>
          <div className="w-full flex flex-col md:flex-row items-center justify-center gap-[16px]">
            {i.routerLinkXl(
              i.ROUTER.HOME,
              "Home",
              i.WebsiteData.colors[1],
              false
            )}
            {i.routerLinkXl(
              i.ROUTER.ALL_GAMES,
              "Games",
              i.WebsiteData.colors[1],
              false
            )}
            {i.routerLinkXl(
              i.ROUTER.CONTACT,
              "Contact Us",
              i.WebsiteData.colors[1],
              false
            )}
            {i.routerLinkXl(
              i.ROUTER.ABOUT_US,
              "About us",
              i.WebsiteData.colors[1],
              false
            )}
            {i.routerLinkXl(
              i.ROUTER.COOKIES,
              "Cookies & Privacy",
              i.WebsiteData.colors[1],
              false
            )}
            {i.routerLinkXl(
              i.ROUTER.TERMS,
              "Terms & Conditions",
              i.WebsiteData.colors[1],
              false
            )}
          </div>
        </div>
        <div className="w-full h-fit flex mx-[5%] flex-col gap-[10px] sm:flex-row items-center justify-center gap-[16px] m-[2rem] flex-wrap">
          <div
            style={{
              color: i.getOppositeColor(i.WebsiteData.colors[1]),
            }}
          >
            <i.LocalPhoneIcon
              style={{
                fill: i.getOppositeColor(i.WebsiteData.colors[1]),
              }}
            />{" "}
            {i.WebsiteData.phone}
          </div>
          <div className="text-break text-center">
            <i.AlternateEmailIcon
              style={{
                fill: i.getOppositeColor(i.WebsiteData.colors[1]),
              }}
            />{" "}
            <span
              style={{
                color: i.getOppositeColor(i.WebsiteData.colors[1]),
              }}
              className="text-center"
            >
              support @{i.WebsiteData.campaign_title.split(" ").join("")}.com
            </span>
          </div>
        </div>
        <p
          className="text-center mx-[5%] font-[16px] text-break"
          style={{
            color: i.getOppositeColor(i.WebsiteData.colors[1]),
          }}
        >
          Get ready to dive into a world of thrilling games designed purely for
          your entertainment. At {i.WebsiteData.campaign_title}, we provide a
          free online social casino platform where all game activities and
          rewards are virtual and hold no monetary value. This means you can
          enjoy the excitement of casino games without any risk to your real
          money. Please be aware that {i.WebsiteData.campaign_title} is meant
          for individuals aged 19 and older. By using our platform, you affirm
          that you meet this age requirement.{" "}
        </p>
        <div className="flex justify-center items-center mx-[80px]">
          <img src={i.imgs.footerImg} alt="footer img" />
        </div>
        <div
          className="flex justify-center items-center mx-[5%] text-center"
          style={{
            color: i.getOppositeColor(i.WebsiteData.colors[0]),
          }}
        >
          {i.WebsiteData.campaign_title} © {year} All Rights Reserved
        </div>
      </div>
    </div>
  );
};
