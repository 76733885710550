import * as i from "./imports";
export const BaseLayout = ({
  children,
  cl,
}: {
  children: i.ReactNode;
  cl?: string;
}) => {
  const path = i.useLocation();

  const urlPageCheck =
    path.pathname === i.ROUTER.PAGE || path.pathname === `${i.ROUTER.PAGE}/`;

  const backgroundStyle = urlPageCheck
    ? "bg-black"
    : `linear-gradient(to bottom, ${i.WebsiteData?.colors[0]}, ${i.WebsiteData?.colors[1]})`;

  const backgroundImage = !urlPageCheck
    ? `url(${i.WebsiteData?.bg})`
    : undefined;

  return (
    <div
      className={`flex flex-col min-h-screen w-full relative ${cl} items-center justify-center ${
        urlPageCheck ? "bg-black" : ""
      }`}
      style={{
        background: backgroundStyle,
      }}
    >
      <div
        className={`absolute inset-0 h-full ${
          urlPageCheck ? "bg-black" : "bg-center bg-cover pointer-events-none"
        }`}
        style={{
          backgroundImage,
          opacity: 0.3,
        }}
      />
      <div
        className={`flex flex-col w-full ${
          urlPageCheck ? "relative flex-grow z-10" : "lg:w-[75%]"
        } items-center justify-center`}
      >
        {!urlPageCheck && <i.Header />}
        <main
          className={`z-999 ${
            urlPageCheck
              ? "w-full h-fit flex items-start justify-center"
              : "flex-grow px-[5%]"
          }`}
        >
          {children}
        </main>
        {!urlPageCheck && <i.Footer />}
      </div>
    </div>
  );
};
