export const imgs = {
  headerLogo:
    "	https://rocketjackpots.com/wp-content/uploads/2024/07/ROCKET-JACKPOTS-1.png",
  bgImg:
    "https://rocketjackpots.com/wp-content/themes/rocketjackpots/dist/4b644d0c382433825246.png",
  footerImg: "https://i.ibb.co/dJTKTgP/footer-block-img.webp",
  bodySteam:
    "https://rocketjackpots.com/wp-content/themes/rocketjackpots/images/cloudbg.png",
  bodyRocket:
    "https://rocketjackpots.com/wp-content/themes/rocketjackpots/images/rocket.png",
  whyChooseUsImg:
    "https://rocketjackpots.com/wp-content/themes/rocketjackpots/dist/e31612913b7f482d9aac.png",
  noPurchase:
    "https://rocketjackpots.com/wp-content/themes/rocketjackpots/images/no-purchase-necessary.png",
  plus18:
    "https://static.vecteezy.com/system/resources/previews/012/982/518/original/sign-of-adult-only-icon-symbol-for-eighteen-plus-18-plus-and-twenty-one-plus-21-plus-age-format-png.png",
};

export const images = {
  defaultCasino: "https://cdn.worldvectorlogo.com/logos/win-online-casino.svg",
  casinoStars:
    "http://canadaluckycash.com/wp-content/uploads/2024/09/header_default.png",
};
