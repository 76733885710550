export enum ROUTER {
  HOME = "/",
  CONTACT = "/contact",
  NEWEST_GAMES = "/newest-games",
  HOTTEST_GAMES = "/hottest-games",
  ALL_GAMES = "/all-games",
  ABOUT_US = "/about-us",
  CONTACT_US = "/contact-us",
  TERMS = "/terms-conditions",
  COOKIES = "/privacy-policy-cookies",
  PAGE = "/page",
  ALL_IN_ONE = "/all-in-one",
}
