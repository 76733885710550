import React from "react";

// Функция для инвертирования цвета
function invertColor(hex: string): string {
  // Убираем символ #, если он есть
  hex = hex.replace("#", "");

  // Получаем RGB значения как числа
  let r: number = parseInt(hex.substring(0, 2), 16);
  let g: number = parseInt(hex.substring(2, 4), 16);
  let b: number = parseInt(hex.substring(4, 6), 16);

  // Инвертируем значения
  r = 255 - r;
  g = 255 - g;
  b = 255 - b;

  // Преобразуем значения обратно в строку в формате HEX
  const invertedColor = `#${r.toString(16).padStart(2, "0")}${g
    .toString(16)
    .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

  // Возвращаем инвертированный цвет
  return invertedColor;
}

// Типизация пропсов компонента
interface TextWithInverseColorProps {
  bgColor: string; // Ожидаем строку с цветом в формате HEX
  children: React.ReactNode; // Дети компонента могут быть любым контентом
}

// React компонент с типами
export const TextWithInverseColor: React.FC<TextWithInverseColorProps> = ({
  bgColor,
  children,
}) => {
  const textColor = invertColor(bgColor);

  return (
    <div style={{ backgroundColor: bgColor, color: textColor }}>{children}</div>
  );
};
